





































































import Vue from 'vue';
import ResponsePagingInfo from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/responsePagingInfo';
import GetAccessCodeListRequestViewModel from '@/src/services/viewModel/request/LearnitAccessCodes/GetAccessCodeListRequestViewModel';
import LearnitAccessCodeResourceViewModel from '@/src/services/viewModel/resource/LearnitAccessCodeResourceViewModel';

export default Vue.extend({
  auth: true,
  role: ['admin', 'hostadmin'],
  layout: 'layout-hostadmin',
  data: () => ({
    accessCodeBeingEdited: new LearnitAccessCodeResourceViewModel(),
    editAccessCodeDialogVisible: false,
    requestError: undefined as any,
    filter: {
      searchCriteria: undefined as string | undefined,
    },
    columns: [] as {}[],
    list: {},
    pagination: {
      pageSize: 10,
      currentPage: 1,
    } as ResponsePagingInfo,
    innerPagination: {
      'paging.CurrentPage': undefined as number | undefined,
      'paging.PageSize': undefined as number | undefined,
      'paging.IncludeCount': undefined as boolean | undefined,
      'paging.NoPaging': undefined as boolean | undefined,
    },
  }),
  computed: {
    request() {
      return new GetAccessCodeListRequestViewModel().fromModel({ ...this.filter, ...this.innerPagination });
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.pagination.currentPage = 1;
        this.innerPagination['paging.CurrentPage'] = 1;
        this.$debounce(() => {
          this.refreshList();
        }, 300, this)();
      },
    },
    request() {
      this.writeRequestToQuery();
    },
  },
  beforeMount() {
    this.getQueryFromRequest();
    this.pagination.currentPage = this.innerPagination['paging.CurrentPage']! ?? 1;
    this.pagination.pageSize = this.innerPagination['paging.PageSize']!;
  },
  mounted() {
    this.columns = [
      { value: '', field: 'filter' },
      { value: this.$t('page.admin.accessCodes.label.code'), textAlign: 'left', field: 'code' },
      { value: this.$t('page.admin.accessCodes.label.organisation'), textAlign: 'left', field: 'organisation' },
    ];
  },
  methods: {
    getQueryFromRequest() {
      const queries = new GetAccessCodeListRequestViewModel();
      queries.fromQuery(this.$routerUtils.getQueryParams());
      this.filter.searchCriteria = queries.searchCriteria;

      this.innerPagination['paging.CurrentPage'] = queries['paging.CurrentPage'];
      this.innerPagination['paging.PageSize'] = queries['paging.PageSize'];
      this.innerPagination['paging.IncludeCount'] = queries['paging.IncludeCount'];
      this.innerPagination['paging.NoPaging'] = queries['paging.NoPaging'];
    },
    async writeRequestToQuery() {
      this.$debounce(() => {
        this.$routerUtils.updateQueryParams(this.request.toQuery());
      }, 200, this.writeRequestToQuery)();
    },
    refreshList() {
      const accessCodeList = this.$refs.accessCodeList as any;
      accessCodeList?.populateList();
    },
    populateData(params: any) {
      this.innerPagination['paging.CurrentPage'] = params.query['paging.CurrentPage'];
      this.innerPagination['paging.PageSize'] = params.query['paging.PageSize'];
      this.innerPagination['paging.IncludeCount'] = params.query['paging.IncludeCount'];
      this.innerPagination['paging.NoPaging'] = params.query['paging.NoPaging'];
      return this.$service.api.learnitAccessCodes.getAccessCodeList(this.request);
    },
    showEditDialog(accessCode?: LearnitAccessCodeResourceViewModel) {
      if (accessCode === undefined) {
        this.accessCodeBeingEdited = new LearnitAccessCodeResourceViewModel();
      } else {
        this.accessCodeBeingEdited = new LearnitAccessCodeResourceViewModel().fromModel(accessCode as any);
      }
      this.editAccessCodeDialogVisible = true;
    },
    async createOrUpdateAccessCode(accessCode: LearnitAccessCodeResourceViewModel) {
      if (this._.isSet(accessCode.id)) {
        await this.editAccessCode(accessCode);
      } else {
        await this.createAccessCode(accessCode);
      }
    },
    async createAccessCode(accessCodeBeingEdited: LearnitAccessCodeResourceViewModel) {
      try {
        await this.$service.api.learnitAccessCodes.createAccessCode(accessCodeBeingEdited);
        this.refreshList();
      } catch (error: any) {
        this.requestError = error;
      }
      this.editAccessCodeDialogVisible = false;
    },
    async editAccessCode(updateAccessCode: LearnitAccessCodeResourceViewModel) {
      const o = new LearnitAccessCodeResourceViewModel();
      o.id = updateAccessCode.id;
      o.code = updateAccessCode.code;
      o.organisation = updateAccessCode.organisation;
      try {
        await this.$service.api.learnitAccessCodes.updateAccessCode(o);
        this.refreshList();
      } catch (error: any) {
        this.requestError = error;
      }
      this.editAccessCodeDialogVisible = false;
    },
    async deleteAccessCode(id: number) {
      const confirmed = await this.$dialog.confirmDelete();
      if (!confirmed) {
        return;
      }
      try {
        await this.$service.api.learnitAccessCodes.deleteAccessCode(id);
        this.refreshList();
      } catch (error: any) {
        this.requestError = error;
      }
    },
  },
});
